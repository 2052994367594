import React, { Component } from 'react'
import posed, { PoseGroup} from 'react-pose'
import SplitText from 'react-pose-text';
import styled from 'styled-components'
import CenteredWrapper from '../../atoms/CenteredWrapper'
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

const Section = posed.section({
  enter: { beforeChildren: true, staggerChildren: 60 },
  exit: { staggerChildren: 20 },
})

const charPosesH2 = {
  exit: { opacity: 0 },
  enter: { opacity: 1 }
};

const charPosesh2 = {
  exit: { opacity: 0, y: 10 },
  enter: {
    opacity: 1,
    y: 0,
  }
};

const H2 = styled.h2`
  ${tw`flex-1 lg:flex-auto text-white text-left font-mono px-3 py-4 text-center text-lg lg:text-2xl`}
`

const H1 = posed.h1({
  enter: {
    y: 0,
    opacity: 1
  },
  exit: {
    y: -60,
    opacity: 0
  },
})

class HomeIntro extends Component {
  state = { isVisible: false };

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        isVisible: !this.state.isVisible
      });
    }, 2500);
  }

  render() {
    const { isVisible } = this.state;
    const { intl } = this.props;

    return(
      <PoseGroup>
        {isVisible
          ? <Section key="homeIntro" className={`w-full h-screen flex`}>
              <CenteredWrapper>
                <div className="h1 text-primary text-center font-mono text-white text-xl lg:text-2xl">
                  HI!
                </div>
                <h1 className={`text-white text-left py-5 text-center font-mono text-2xl lg:text-4xl`}>
                  <SplitText charPoses={charPosesH2}>We are Soulweb Solutions</SplitText>
                </h1>
                <div className="flex flex-col lg:flex-row justify-center">
                  <H2>
                    <SplitText charPoses={charPosesh2}>Technology</SplitText>
                  </H2>
                  <H2>
                    <SplitText charPoses={charPosesh2}>Innovation</SplitText>
                  </H2>
                  <H2>
                    <SplitText charPoses={charPosesh2}>Research</SplitText>
                  </H2>
                  <H2>
                    <SplitText charPoses={charPosesh2}>Knowledge</SplitText>
                  </H2>
                </div>
              </CenteredWrapper>
            </Section>
          : null
        }
      </PoseGroup>
    )
  }
}

export default injectIntl(HomeIntro);
