import React from 'react'
import SEO from "../components/atoms/seo"
import { Link } from 'gatsby'
import posed from 'react-pose'
import styled from 'styled-components'
import FullPageSlide from '../components/organisms/FullPageSlide'
import BackgroundAnimation from '../components/organisms/BackgroundAnimation'
import ScrollDown from '../components/atoms/ScrollDown'
import HomeIntro from '../components/organisms/sections/HomeIntro'
import BrandName from '../components/atoms/BrandName'

const Section = posed.section({
  enter: {
    staggerChildren: 50
  },
  exit: {
    staggerChildren: 20
  },
})

const SectionStyled = styled(Section)`
  background-color: transparent;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  overflow: hidden;
`

function IndexPage() {
  return (
    <FullPageSlide slideNumber={0} prev={'/services/'} next={'/about/'}>
      <SEO
        title="Home"
        keywords={[`soulweb`, `solutions`]}
      />

      <BackgroundAnimation/>

      <BrandName/>

      <SectionStyled>
        <HomeIntro/>
      </SectionStyled>

      {/*<ScrollDown scrollTo={'/about/'}/>*/}
    </FullPageSlide>
  )
}

export default IndexPage
