import React from 'react'

const CenteredWrapper = props => (
  <div
    className={
      `flex self-center justify-center w-full ${props.className ? props.className: ''}`
    }
  >
    <div className={`centered-inner`}>
      {props.children}
    </div>
  </div>
)

export default CenteredWrapper
