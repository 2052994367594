import React from 'react'
import posed, { PoseGroup } from 'react-pose'
import styled from 'styled-components'
import BackgroundElement from './BackgroundElement'

const PanelPose = posed.div({
  enter: {
    staggerChildren: 100,
  },
  exit: {
    staggerChildren: 100,
  },
});

const PanelWrapper = styled(PanelPose)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`

class BackgroundAnimation extends React.Component {
  state = { isVisible: false };

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        isVisible: !this.state.isVisible
      });
    }, 1000);
  }

  render() {
    const { isVisible } = this.state;

    return(
      <PoseGroup>
        {isVisible
          ? <PanelWrapper key="BackgroundMain">
              <BackgroundElement
                bgColor={'#b82601'}
                enterY={0}
                enterOpacity={1}
                enterRotate={-50}
                exitY={'100%'}
                exitOpacity={0}
                exitRotate={0}
                transformOrigin={'top right'}
              />
              <BackgroundElement
                bgColor={'#062f4f'}
                enterY={'0'}
                enterOpacity={'1'}
                enterRotate={'60'}
                exitY={'100%'}
                exitOpacity={0}
                exitRotate={0}
                transformOrigin={'bottom right'}
              />
              <BackgroundElement
                bgColor={'#000000'}
                enterY={0}
                enterOpacity={1}
                enterRotate={50}
                exitY={'100%'}
                exitOpacity={0}
                exitRotate={0}
                transformOrigin={'top left'}
              />
              <BackgroundElement
                bgColor={'#626e60'}
                enterY={0}
                enterOpacity={1}
                enterRotate={-40}
                exitY={'100%'}
                exitOpacity={0}
                exitRotate={0}
                transformOrigin={'bottom left'}
              />
            </PanelWrapper>
          : null}
      </PoseGroup>
    )
  }
}

export default BackgroundAnimation
