import posed from 'react-pose'
import styled from 'styled-components'

const BackgroundPose = posed.div({
  enter: {
    y: (props) => parseFloat(props.enterY, 10),
    opacity: (props) => parseFloat(props.enterOpacity, 10),
    rotate: (props) => parseFloat(props.enterRotate, 10),
  },
  exit: {
    y: (props) => parseFloat(props.exitY, 10),
    opacity: (props) => parseFloat(props.exitOpacity, 10),
    rotate: (props) => parseFloat(props.exitRotate, 10)
  }
})

const BackgroundElement = styled(BackgroundPose)`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background-color: ${props => props.bgColor};
  transform-origin: ${props => props.transformOrigin};
  overflow: hidden;
`

export default BackgroundElement
